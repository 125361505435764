import { createGlobalStyle } from 'styled-components'
import { COLORS, FONTS, SIZES } from './theme'

const GlobalStyle = createGlobalStyle`
  /* body > #zmmtg-root {
    display: none;
  } */

  html {
    color: ${COLORS.text};
  }

  body {
    font-family: ${FONTS.primary};
  }

  #innerBody {
    background: linear-gradient(135deg, #ffffff, #8f9bab);
    background-size: 200% 200%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${FONTS.secondary};
  }

  * {
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  *:focus {
      outline: none !important;
  }

  .text-red {
    color: ${COLORS.primary} !important;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spacer-100 {
    height: 100px;
  }

  #live-pw {
    line-height: 1.2;
    text-align: center;
    font-size: .7rem;
    font-weight: bold;
    border-radius: 2px;
    padding: 3px;
    .pw {
      letter-spacing: 1px;
      margin: 0;
      font-weight: bold;
      color: ${COLORS.primary};
      font-size: 1.3em;
    }

    ${SIZES.mobile} {
      font-size: .5em;
      .pw {
        font-size: 1.2em;
      }
    }
  }

  .Toastify__toast {
    color: ${COLORS.text};
    border-radius: 4px;
    font-family: ${FONTS.primary};
    padding: 8px 14px;
    background: rgba(255, 255, 255, .9);
    border-left: solid 6px #E2001A;

    &.Toastify__toast--error {
      border: solid 1px rgba(255, 129, 129, 1);
      border-left: solid 6px rgba(255, 129, 129, 1);
    }
  }

  .Toastify__toast-container {
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    15% {
      transform: scale(0.8);
    }
    30% {
      transform: scale(1.08);
    }
    60% {
      transform: scale(0.93);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes mousepoint {
    0% {
      left: 40%;
      bottom: 60%;
    }
    100% {
      left: 30%;
      bottom: 60%;
    }
  }
  @keyframes appear {
    to { opacity: 1; }
  }
  @keyframes disappear {
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      display: none;
      z-index: -10;
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-8%);
    }
    60% {
      transform: translateY(-4%);
    }
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
`

export default GlobalStyle

import amplitude from 'amplitude-js'
import config from '../config'

const Amplitude = {
  init: () => {
    amplitude.getInstance().init(config.API_KEYS.amplitude)
  },

  // TODO: Put all amplitude tracking functions here
  visit: (page) => {
    amplitude.getInstance().logEvent(`Visit ${page}`)
  },

  visitExhibition: (hallNumber) => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber,
    })
  },

  visitBooth: (boothName) => {
    amplitude.getInstance().logEvent(`Visit Booth`, {
      boothName: boothName,
    })
  },

  viewAbstractPresentation: (abstract, type) => {
    amplitude.getInstance().logEvent(`View Abstract Presentation`, {
      abstractId: abstract.id,
      abstractTitle: abstract.title,
      abstractType: type,
    })
  },
  
  viewGalleryVideo: (title, category) => {
    amplitude.getInstance().logEvent(`View Gallery Video`, {
      title,
      category,
    })
  },
  
  clickQuizKiosk: () => {
    amplitude.getInstance().logEvent('Click Booth Quiz')
  },
  submitQuiz: () => {
    amplitude.getInstance().logEvent('Quiz Submitted')
  },

  showQR: () => {
    amplitude.getInstance().logEvent('Show QR')
  },

  clickHelpDesk: () => {
    amplitude.getInstance().logEvent('Click Lobby Helpdesk')
  },

  clickMute: () => {
    amplitude.getInstance().logEvent('Click Mute button');
  },

  clickLogout: () => {
    amplitude.getInstance().logEvent('Click Logout');
  },

  clickMenu: () => {
    amplitude.getInstance().logEvent('Click Menu')
  },

  watchPlayback: (id) => {
    amplitude.getInstance().logEvent('Watch Playback', {
      sessionId: id
    })
  },

  clickNotification: () => {
    amplitude.getInstance().logEvent('Click Notifications')
  },

  clickAgenda: () => {
    amplitude.getInstance().logEvent('Click Agenda Modal')
  },

  clickSpeaker: () => {
    amplitude.getInstance().logEvent('Click Speaker Modal')
  },

  clickProgrammeButton: () => {
    amplitude.getInstance().logEvent('Click Programme List')
  },

  clickFaculty: () => {
    amplitude.getInstance().logEvent('Click Faculty Button')
  },

  clickFacultyModal: (id) => {
    amplitude.getInstance().logEvent('Click Faculty Modal', {
      speakerId: id
    })
  },

  clickOfficeBearer: () => {
    amplitude.getInstance().logEvent('Click Office Bearer Modal')
  },

  clickOrganisingCommittee: () => {
    amplitude.getInstance().logEvent('Click Organising Committee Modal')
  }, 

  clickHotButtons: (boothName, type) => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: boothName,
      hotButtonType: type,
    })
  },

  clickHint: (boothName) => {
    amplitude.getInstance().logEvent('Click Booth Hint', {
      boothName: boothName,
    })
  },

  collectCoin: (missionId) => {
    amplitude.getInstance().logEvent('Collect Coin', {
      missionId: missionId,
    })
  },

  enterDraw: () => {
    amplitude.getInstance().logEvent('Click Enter Draw')
  },

  submitQuestion: (details) => {
    amplitude.getInstance().logEvent('Submit Gsheets Question', {
      UserID: details.id,
      FullName: details.fullName,
      Email: details.email,
      Question: details.question,
      HallName: details.hallName
    });
  },

  uploadImage: () => {
    amplitude.getInstance().logEvent('Upload profile image');
  },

  clickPodcast: (title) => {
    amplitude.getInstance().logEvent(`Click podcast: ${title}`)
  },

  dropNamecard: (details) => {
    amplitude.getInstance().logEvent('Drop name card', {
      SenderID: details.senderId,
      SenderFullName: details.senderFullName,
      SenderEmail: details.senderEmail,
      ReceiverFullName: details.receiverFullName,
      ReceiverEmail: details.receiverEmail,
    });
  }
}

export default Amplitude

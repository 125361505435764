import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import routes from 'src/routes'
import { ScreenStyle } from './styled'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import config from 'src/config'
import { enterDraw, getBooths, refreshUser, showGlobalModal } from 'src/ducks/game'
import ModalContainer from './modals/ModalContainer'
import { fixedAssets } from 'src/containers/PreCache'

export const HomeScreen = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentUser, isAuthenticated, booths } = useSelector((state) => state.game)
  const [search, setSearch] = useState('')
  const [delay, setDelay] = useState(0)

  const sortedBooths = [...booths].sort((a, b) => a.id - b.id)

  useEffect(() => {
    document.title = `${config.eventName} | Home`
    dispatch(getBooths(search))
  }, [dispatch])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(refreshUser())
    } else {
      history.push(routes.root)
    }
  }, [dispatch, history, isAuthenticated])

  const clickHow = (type) => {
    dispatch(showGlobalModal(type))
  }

  const showScanner = (booth) => {
    if (booth.claimed) {
      console.log('do nothing')
    } else {
      history.push(`/scanner/${booth.id}`)
    }
  }
  const submitEntry = () => {
    dispatch(enterDraw())
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setSearch(value.toLowerCase())

    if (delay) {
      clearTimeout(delay)
    }

    setDelay(
      setTimeout(() => {
        dispatch(getBooths(value.toLowerCase()))
      }, 700)
    )
  }

  return (
    <ScreenStyle>
      <img src={fixedAssets.banner} className="masterhead" alt="Event banner" />
      <header className="home-container">
        <div className="profile">
          <p>{currentUser.fullName}</p>
          <p>{currentUser.email}</p>
        </div>
        <div className="game-status">
          <div>
            <strong>
              {currentUser.total_points} / {booths.length}
            </strong>
          </div>
          <small>Stamps Collected</small>
        </div>
      </header>

      <div className="search-wrapper">
        <div className="inputWrapper">
          <input className="search-input" type="text" value={search} placeholder="Search Name" onChange={handleInputChange} />
          <svg width="14.268" height="14.268" viewBox="0 0 14.268 14.268">
            <path
              d="M11.88,9.982a5.642,5.642,0,1,0-1.9,1.9l3.828,3.828,1.9-1.9Zm-4.811.571a3.481,3.481,0,1,1,3.483-3.479A3.486,3.486,0,0,1,7.069,10.553Z"
              transform="translate(-1.44 -1.44)"
            />
          </svg>
        </div>
        <div className="how-btn" onClick={() => clickHow('welcome')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 10C0 4.61522 4.36522 0.25 9.75 0.25C15.1348 0.25 19.5 4.61522 19.5 10C19.5 15.3848 15.1348 19.75 9.75 19.75C4.36522 19.75 0 15.3848 0 10ZM11.3777 6.08328C10.4889 5.30557 9.01159 5.30557 8.12278 6.08328C7.81106 6.35604 7.33723 6.32445 7.06447 6.01272C6.79171 5.701 6.8233 5.22717 7.13503 4.95441C8.58937 3.68186 10.9111 3.68186 12.3654 4.95441C13.8785 6.27835 13.8785 8.47165 12.3654 9.79559C12.1088 10.0202 11.8261 10.2041 11.528 10.3484C10.8518 10.6756 10.5002 11.1222 10.5002 11.5V12.25C10.5002 12.6642 10.1644 13 9.75023 13C9.33601 13 9.00023 12.6642 9.00023 12.25V11.5C9.00023 10.221 10.0595 9.39264 10.8746 8.99817C11.0573 8.90977 11.2265 8.79899 11.3777 8.66672C12.2077 7.9404 12.2077 6.80959 11.3777 6.08328ZM9.75 16C10.1642 16 10.5 15.6642 10.5 15.25C10.5 14.8358 10.1642 14.5 9.75 14.5C9.33579 14.5 9 14.8358 9 15.25C9 15.6642 9.33579 16 9.75 16Z"
              fill="#E97D8A"
            />
          </svg>
          <span className="how-to-play">How To Play?</span>
        </div>
      </div>
      {currentUser.entered ? (
        <>
          <div className="game-entered">
            <span>Entry submitted</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM13.7029 8.13941C13.9498 7.79371 13.8697 7.31329 13.524 7.06636C13.1783 6.81943 12.6979 6.8995 12.451 7.2452L9.13261 11.8909L7.46701 10.2253C7.1666 9.9249 6.67955 9.9249 6.37915 10.2253C6.07875 10.5257 6.07875 11.0128 6.37915 11.3132L8.68684 13.6209C8.84673 13.7807 9.06887 13.8622 9.29422 13.8435C9.51956 13.8249 9.72529 13.708 9.85672 13.524L13.7029 8.13941Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="text-center px-4">You have joined our Lucky Draw.</p>
        </>
      ) : (
        <button onClick={submitEntry} className={`${currentUser.eligible ? `active-submit` : `disable-submit`} submit-button`}>
          <span>SUBMIT ENTRY</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path d="M8.6543 1L14.9999 7.34565L8.6543 13.6913" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.9999 7.34564L1.5 7.34564" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      )}
      <Container className="my-3">
        <Row noGutters>
          {sortedBooths.map((booth) => (
            <Col xs={6} key={booth.id} className="px-4 mb-3" onClick={() => showScanner(booth)}>
              <div className={`${booth.claimed && 'claimed'} boothCard`}>
                <img className="cardBox" src={fixedAssets.box} alt="booth frame" />
                <img className="boothLogo" src={booth.thumbnail} alt="booth logo" />
                {booth.claimed ? (
                  <img className="boothStatus" src={fixedAssets.tick} alt="stamp status" />
                ) : (
                  <svg width="19.047" height="16.326" viewBox="0 0 19.047 16.326">
                    <g transform="translate(-2.25 -4.5)">
                      <path d="M18.927,17.936a2.678,2.678,0,1,1-2.678-2.678,2.678,2.678,0,0,1,2.678,2.678Z" transform="translate(-4.475 -4.253)" />
                      <path d="M19.6,5.86H15.855L14.494,4.5H9.052L7.692,5.86H3.951a1.7,1.7,0,0,0-1.7,1.7V19.125a1.7,1.7,0,0,0,1.7,1.7H19.6a1.7,1.7,0,0,0,1.7-1.7V7.561A1.7,1.7,0,0,0,19.6,5.86ZM11.773,18.445a4.762,4.762,0,1,1,4.762-4.762A4.762,4.762,0,0,1,11.773,18.445Z" />
                    </g>
                  </svg>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      <ModalContainer />
    </ScreenStyle>
  )
}

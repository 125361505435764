import React, { useEffect, useRef } from 'react'


import QrScanner from 'qr-scanner'
QrScanner.WORKER_PATH = `//cdnjs.cloudflare.com/ajax/libs/qr-scanner/1.0.0/qr-scanner-worker.min.js`


export const ScannerBox = ({ setData }) => {
  const videoRef = useRef(null);
  const toggleRef = useRef(null);
  localStorage.setItem('cam_view', 'environment')
  
  useEffect(() => {
    if (!videoRef) return
    if (!toggleRef) return
    const videoElem = videoRef.current

    toggleRef.current.addEventListener('click', () => {
      if (localStorage.getItem('cam_view') === 'user') {
        qrScanner.setCamera('environment')
        localStorage.setItem('cam_view', 'environment')
      } else {
        qrScanner.setCamera('user')
        localStorage.setItem('cam_view', 'user')
      }
    })

    const qrScanner = new QrScanner(
      videoElem,
      (result) => handleQrScan(result, qrScanner),
      {
        maxScansPerSecond: 5,
        highlightScanRegion: true,
      }
    )
    qrScanner.setCamera(localStorage.getItem('cam_view'))
    qrScanner.start()
  }, [videoRef,toggleRef])

  const handleQrScan = (result, qrScanner) => {
    qrScanner.stop()
    setData(result.data)
  }

  return (
    <>
      <div className='scanContainer'>
        <video ref={videoRef} />
      </div>
      <button className='cam-toggle' ref={toggleRef}>
        <svg width="27" height="20.25" viewBox="0 0 27 20.25">
          <path d="M22.043,19.336A4.043,4.043,0,1,1,18,15.293,4.043,4.043,0,0,1,22.043,19.336Z" transform="translate(-4.5 -7.875)" fill="#fff"/>
          <path d="M29.355,11.25H25.523a1.141,1.141,0,0,1-.844-.38c-2-2.236-2.749-3-3.565-3H15.1c-.823,0-1.631.759-3.635,3a1.119,1.119,0,0,1-.837.373h-.288v-.562a.564.564,0,0,0-.563-.562H7.952a.564.564,0,0,0-.562.563v.563H6.862A2.28,2.28,0,0,0,4.5,13.409V25.784a2.434,2.434,0,0,0,2.355,2.341h22.5A2.254,2.254,0,0,0,31.5,25.784V13.409A2.1,2.1,0,0,0,29.355,11.25ZM18.281,25.341A6.011,6.011,0,1,1,24,19.617,6.017,6.017,0,0,1,18.281,25.341ZM24.75,15.328a.914.914,0,1,1,.914-.914A.912.912,0,0,1,24.75,15.328Z" transform="translate(-4.5 -7.875)" fill="#fff"/>
        </svg>
        <span>Toggle Camera</span>
      </button>
    </>
  );
};
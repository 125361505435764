import axios from 'axios'
import config from '../config'

const SEND_REQUEST = 'SEND_REQUEST'
const QUIZ_RESULT = 'QUIZ_RESULT'
const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS'
const SUBMIT_ANSWER = 'SUBMIT_ANSWER'
const APPEND_BOOTH_ANSWER = 'APPEND_BOOTH_ANSWER'
const RESET_BOOTH_ANSWER = 'RESET_BOOTH_ANSWER'
const SENDING_ANSWER = 'SENDING_ANSWER'
const TOP_FIVE = 'TOP_FIVE'

const initialState = {
  apiLoading: false,
  isAuthenticated: localStorage.getItem('token') ? true : false,
  user: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  questions: {},
  userScore: null,
  quizAnswers: [],
  completed: false,
  winners: [],
}

export default function quizReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_REQUEST:
      return {
        ...state,
        apiLoading: true,
      }
    case QUIZ_RESULT:
      return {
        ...state,
        completed: action.completed,
        userScore: action.userScore,
      }  
    case RECEIVE_QUESTIONS:
      return {
        ...state,
        apiLoading: false,
        questions: action.questions,
      }
    case APPEND_BOOTH_ANSWER:
      return {
        ...state,
        quizAnswers: {
          [action.boothId]: action.questionAnswer,
        },
      }
    case RESET_BOOTH_ANSWER:
      return {
        ...state,
        quizAnswers: {
          [action.boothId]: [],
        },
      }
    case SENDING_ANSWER:
      return {
        ...state,
        submitting: true,
      }
    case SUBMIT_ANSWER:
      return {
        ...state,
        submitting: false,
        userScore: action.userScore,
      }

    case TOP_FIVE:
      return {
        ...state,
        winners: action.winners
      }
    default:
      return state
  }
}

export function getResult() {
  return (dispatch) => {

    return axios
      .get(`${config.apiUrl}/api/questionaires`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(({ status, data }) => {
        // console.log(data)
        if (status === 200 && data.success) {
          dispatch(quizResult(data.completed, data.user_score))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getQuestions(qId) {
  return (dispatch) => {
    dispatch(requestData())

    return axios
      .get(`${config.apiUrl}/api/questionaires/${qId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(({ status, data }) => {
        // console.log(data)
        if (status === 200 && data.success) {
          dispatch(receiveQuestions({
            ...data.questionaire.questions,
            questionCount: data.question_count
          }))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getWinners() {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/users/ranking`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(({ status, data }) => {
        // console.log(data)
        dispatch(receiveWinners(data.winners))
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function submitAnswer(qId, data) {
  return (dispatch) => {
    dispatch(sendingAnswer())

    return axios
      .post(`${config.apiUrl}/api/questionaires/${qId}/complete_questionaire`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ status, data }) => {
        console.log(data)
        if (data.success) {
          dispatch(showScore(data.user_score))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

function requestData() {
  return {
    type: SEND_REQUEST,
  }
}
function quizResult(completed, userScore) {
  return {
    type: QUIZ_RESULT,
    completed,
    userScore,
  }
}

function receiveQuestions(questions) {
  return {
    type: RECEIVE_QUESTIONS,
    questions,
  }
}
export function appendAnswers(boothId, questionAnswer) {
  return {
    type: APPEND_BOOTH_ANSWER,
    boothId,
    questionAnswer,
  }
}
export function resetAnswers(boothId) {
  return {
    type: RESET_BOOTH_ANSWER,
    boothId,
  }
}
function sendingAnswer() {
  return {
    type: SENDING_ANSWER,
  }
}
function receiveWinners(winners) {
  return {
    type: TOP_FIVE,
    winners,
  }
}
function showScore(userScore) {
  return {
    type: SUBMIT_ANSWER,
    userScore,
  }
}

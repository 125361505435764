import React, { useMemo, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'

import config from './config'
import { PreCache } from './containers/PreCache'
import routes from './routes'

import { EntranceScreen } from './screens/EntranceScreen'
import { HomeScreen } from './screens/HomeScreen'
import { ScannerScreen } from './screens/ScannerScreen'



function App() {
  useEffect(() => {
    document.title = config.eventName
  }, [])

  return useMemo(() => {
    return (
      <div id="innerBody">
        <PreCache>
          <Switch>
            <Route exact path={routes.root} component={EntranceScreen} />
            <Route exact path={routes.lobby} component={HomeScreen} />
            <Route exact path="/scanner/:boothId" component={ScannerScreen} />
          </Switch>
        </PreCache>
      </div>
    )
  }, [])
}

export default App

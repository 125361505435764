import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'


const StyledModal = styled(Modal)`
  .modal-content {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border: none;
    border-radius: 12px;
    background: #F2F2F2;
  }
  .modal-header {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    .close {
      color: #60605F;
      opacity: 1;
      padding: 16px;
      font-size: 34px;
      font-weight: 100;
      text-shadow: none;
    }
  }
  .modal-body {
    margin: 0 auto;
    padding-top: 0;
    padding-left: 24px;
    text-align: center;
    p {
      font-size: 16px;
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 8px;
      color: #01a000;
      font-weight: 600;
    } 
  }

`

export default function SuccessModal({ boothName, showModal, hideModal }) {
  
  return (
    <StyledModal backdrop={false} show={showModal} onHide={hideModal} aria-labelledby="bag-modal" size="lg" centered >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='title-wrapper'>
          <svg width="28.488" height="28.488" viewBox="0 0 28.488 28.488">
            <path d="M9.058,0a1.037,1.037,0,0,1,1.048,1.029.757.757,0,0,0,.769.741h1.384A3.841,3.841,0,0,1,16.1,5.53v.261c1.328,0,2.656.027,4,.041a8.041,8.041,0,0,1,8.387,8.233V20.2A8.041,8.041,0,0,1,20.1,28.433c-1.943.041-3.886.055-5.843.055s-3.928-.014-5.871-.055A8.041,8.041,0,0,1,0,20.2V14.065A8.045,8.045,0,0,1,8.4,5.832c1.831-.027,3.7-.055,5.605-.055V5.544a1.741,1.741,0,0,0-1.747-1.715H10.875a2.841,2.841,0,0,1-2.866-2.8A1.046,1.046,0,0,1,9.058,0Zm1.034,13.626a1.037,1.037,0,0,0-1.048,1.029V16.1H7.562a1.029,1.029,0,1,0,0,2.058H9.044v1.455a1.049,1.049,0,0,0,2.1,0V18.155h1.468a1.029,1.029,0,1,0,0-2.058H11.141V14.655A1.046,1.046,0,0,0,10.092,13.626ZM21.079,18.5h-.14a1.029,1.029,0,1,0,0,2.058h.14a1.029,1.029,0,1,0,0-2.058Zm-2.39-4.72h-.14a1.029,1.029,0,1,0,0,2.058h.14a1.029,1.029,0,1,0,0-2.058Z" fill="#01a000"/>
          </svg>
          <span>Success</span>
        </div>
        <p className='my-3 text-black'>You have collected <strong>“{boothName}”</strong> stamp.</p>
      </Modal.Body>
    </StyledModal>
  )
}

import routes from './routes'
import { LobbyEntryProps, LobbyEntryTypes } from './components/LobbyEntry/LobbyEntry'

const dev = {
  apiUrl: 'http://localhost:3000',
  // apiUrl: 'https://msc2023-b344024ca0a0.herokuapp.com',
}

const prod = {
  apiUrl: 'https://asad2024.com/',
}

const API_KEYS = {
  amplitude: '',
}

// Root page will show either EntrancePage or CountdownPage
// based on the following time setting
const EVENT_START_TIME = {
  year: 2022,
  month: 7,
  day: 17,
  hour: 20,
  minute: 8,
}

const config = process.env.NODE_ENV === 'production' ? prod : dev

const layout = {
  auditoriumVid: `
    top: 20%;
    bottom: 28%;
    left: 15%;
    right: 34%;
  `,
}

const lobbyEntries: Array<LobbyEntryProps & { id: string }> = [
  {
    id: 'gameroom',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.networking,
    css: `
      top: 0%;
      left: 66.4%;
      right: 26%;
      bottom: 63.8%;
    `,
  },
]

export default {
  API_KEYS,
  layout,
  lobbyEntries,
  EVENT_START_TIME,

  eventName: '18th International Conference of ASAD 2024',

  ...config,
}

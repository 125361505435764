import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyB1ezJ0IJ3Z0_7PTgM7Oim-UftHXbZMauA',
  authDomain: 'cloudexpo-demo.firebaseapp.com',
  databaseURL: 'https://cloudexpo-demo-default-rtdb.firebaseio.com',
  projectId: 'cloudexpo-demo',
  storageBucket: 'cloudexpo-demo.appspot.com',
  messagingSenderId: '577690937394',
  appId: '1:577690937394:web:7b7e7d69ab66a0ce88342b',
  measurementId: 'G-W29T4RWDBG',
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const getDbRef = (dbRef) => {
  const envDbRef = `${process.env.NODE_ENV === 'production' ? 'production' : 'local'}/${dbRef}`
  return firebaseDb.ref(envDbRef)
}

const ChatDb = getDbRef('chat')
const HallOneDb = getDbRef('Hall_A_Question')
const HallTwoDb = getDbRef('Hall_B_Question')
const HallOneChatDb = getDbRef('Hall_A_Chat')
const HallTwoChatDb = getDbRef('Hall_B_Chat')
const NoticeDb = getDbRef('notice')
const NotificationDb = getDbRef('pushNotifications')

const getUniqueSessionDb = (uid) => getDbRef(`uniqueSession/${uid}`)
const getUserCountDb = (uid, path) => getDbRef(`userCount/${path}/${uid}`)
const getUserSessionDb = (uid) => getDbRef(`userSessions/${uid}`)
const getOnlineStatusDb = (uid) => getDbRef(`onlineStatus/${uid}`)
const getLiveCheckInSessionDb = (hallNumber) => getDbRef(`liveCheckInSession/hall-${hallNumber}`)
const getRefreshDb = (uid) => getDbRef(`shouldRefresh/${uid}`)

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  HallOneDb,
  HallTwoDb,
  HallOneChatDb,
  HallTwoChatDb,
  NoticeDb,
  NotificationDb,
  getUniqueSessionDb,
  getUserCountDb,
  getUserSessionDb,
  getOnlineStatusDb,
  getLiveCheckInSessionDb,
  getRefreshDb,
}

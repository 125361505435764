const routes = {
  root: '/',
  landing: '/landing',
  preRegister: '/registration',
  register: '/registration/form',
  postRegister: '/registration/complete',
  resetPassword: '/registration/create-password',
  forgotPassword: '/registration/forgot-password',
  login: '/register',
  sso: '/sso',

  notifications: '/notifications',
  menu: '/menu',

  lobby: '/home',

  networking: '/networking-lounge',
  cloudflix: '/highlights',

  auditorium1: '/auditorium-hall-1',
  auditorium2: '/auditorium-hall-2',

  exhibition1: '/exhibition-hall/1',
  exhibition2: '/exhibition-hall/2',
  exhibition3: '/exhibition-hall/3',

  lucky: '/lucky-draw',
  questions: '/live-questions',

  onDemand: '/on-demand',
  onDemandOral: '/on-demand/oral-presentations',
  onDemandPoster: '/on-demand/e-poster-presentations',
  onDemandBbs: '/on-demand/best-basic-science-award',
} as const

type Route = typeof routes
export type RoutesType = Route[keyof Route]

export default routes

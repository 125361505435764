import React, { useEffect, useState } from 'react'
import { ScreenStyle } from './styled'
import { useHistory, useParams } from 'react-router'
import { ScannerBox } from './ScannerBox'
import { fixedAssets } from 'src/containers/PreCache'
import { useDispatch, useSelector } from 'react-redux'
import { getBooth, sendResult } from 'src/ducks/game'
import { Container } from 'react-bootstrap'
import routes from 'src/routes'

export const ScannerScreen = () => {
  const { boothId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [data, setData] = useState('')
  const { booth } = useSelector((state) => state.game)

  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [])

  const sendScanData = () => {
    dispatch(sendResult(history, { booth_id: boothId, qr_label: data }))
  }
  useEffect(() => {
    if (data.length > 0) {
      sendScanData()
    } else {
      // console.log('no result')
    }
  }, [data])

  return (
    <ScreenStyle>
      <img src={fixedAssets.banner} className="masterhead" alt="Event banner" />
      <Container>
        <header className="booth-navigation">
          <div className="prev-booth" onClick={() => history.push(routes.lobby)}>
            <svg width="25" height="25" viewBox="0 0 25 25">
              <g transform="translate(-28 -189)">
                <circle cx="12.5" cy="12.5" r="12.5" transform="translate(28 189)" fill="#6c59ce" />
                <g transform="translate(38.099 197.206)">
                  <path
                    d="M4.865.185a.631.631,0,0,1,0,.893L1.524,4.418,4.865,7.759a.631.631,0,0,1-.893.893L.185,4.865a.631.631,0,0,1,0-.893L3.972.185A.631.631,0,0,1,4.865.185Z"
                    transform="translate(0)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
          <img src={booth?.thumbnail} className="booth-visual" alt="logo" />
          <div className="next-booth"></div>
        </header>

        <ScannerBox setData={setData} />
      </Container>
    </ScreenStyle>
  )
}

import { SIZES } from 'src/styles/theme'
import styled from 'styled-components'

export const ScreenStyle = styled.div`
  background: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 500px;
  margin: auto;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  padding: 0;

  img.masterhead {
    width: 100%;
    position: relative;
    z-index: 100;
  }
  img.visual {
    width: 90%;
    display: block;
    margin: auto;
    margin-top: 45px;
    position: relative;
    z-index: 99;
  }
  .pinText {
    color: var(--Brown, #4a1412);
    text-align: center;
    font-family: Bahnschrift;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    span span {
      position: relative;
      /* bottom: -1em; */
      /* opacity: 0;
      animation: move-text 3s infinite forwards; */
    }
  }
  .resetText {
    color: var(--Brown, #4a1412);
    text-align: center;
    font-family: Bahnschrift;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    margin-top: 20px;
  }
  @keyframes move-text {
    0% {
      bottom: -0.25em;
      opacity: 1;
    }
    50% {
      bottom: 0.25em;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
  form {
    width: 75%;
    display: block;
    margin: 8px auto;
  }
  .login-input {
    width: 100%;
    border: 1px solid #2e388f;
    background: #fff;
    padding: 8px 14px;
    border-radius: 8px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;

    &::placeholder {
      color: #2e388f;
    }
  }
  .cta-btn {
    background: #f9a616;
    border-radius: 24px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    color: #000;
    padding: 8px;
    width: 60%;
    margin: auto;
    display: block;
    transition: all 300ms ease;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: #000;
      color: #f9a616;
    }
  }

  .home-container {
    background: rgba(234, 125, 138, 0.3);
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .profile {
      width: 70%;
    }
    p {
      color: #4a1412;
      font-size: 14px;
      margin: 0;
    }
    .game-status {
      color: #4a1412;
      width: 30%;
      text-align: center;
      strong {
        font-weight: 700;
        font-size: 40px;
      }
    }
  }
  .submit-button,
  .game-entered {
    border: none;
    border-radius: 24px;
    padding: 8px 1rem;
    margin: 8px auto;
    display: block;
    width: 70%;
    span {
      margin-right: 8px;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
  .game-entered {
    border-radius: 30px;
    background: var(--gradient-teal, linear-gradient(180deg, #42bec7 0%, #1589bf 100%));
    color: #fff;
    text-align: center;
  }
  .active-submit {
    background: linear-gradient(135deg, #164af9, #e92927);
    color: #fff;
  }
  .disable-submit {
    background-color: #e4e4e4;
    cursor: not-allowed;
    color: #777777;
  }
  .search-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    .inputWrapper {
      background: #e4e4e4;
      border-radius: 16px;
      padding: 4px 12px;
    }
    .search-input {
      border-radius: 0px;
      border: none;
      background: transparent;
      padding: 0;
      font-size: 14px;
      padding: 0 8px;
    }
    .how-btn {
      display: flex;
      align-items: center;
      color: #e97d8a;
      cursor: pointer;
      span {
        font-size: 14px;
        text-decoration: underline;
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }
  .boothCard {
    position: relative;
    cursor: pointer;
    filter: saturate(0.2) brightness(0.85);
    svg {
      position: absolute;
      top: 12%;
      right: 17%;
    }
  }
  .cardBox {
    position: relative;
    width: 100%;
  }
  .claimed {
    filter: saturate(1);
  }
  .boothLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
  }
  .boothStatus {
    position: absolute;
    top: 5%;
    right: 7%;
  }
  .booth-navigation {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 30px;
      height: 30px;
    }
    .booth-visual {
      max-width: 150px;
    }
    .prev-booth,
    .next-booth {
      cursor: pointer;
    }
  }

  .home-card {
    padding: 12px 16px;
    padding-top: 0px;
    cursor: pointer;
    transition: all 200ms ease;
    img {
      border-radius: 8px;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
  .scanContainer {
    margin: auto;
    max-width: 400px;
    video {
      max-width: 100%;
    }
  }
  .cam-toggle {
    display: block;
    margin: 3rem auto;
    background: linear-gradient(135deg, #525fe1, #753d77);
    border: none;
    color: #fff;
    border-radius: 24px;
    padding: 10px 1rem;
    width: 65%;
    span {
      font-size: 14px;
      margin-left: 8px;
    }
    svg {
      width: 24px;
      height: 20px;
    }
  }
  ${SIZES.mobile} {
    .cta-btn {
      width: 90%;
    }
    form {
      width: 90%;
    }
  }
`

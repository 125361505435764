import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from 'src/components/shared/Loader'
import { cacheWebImages } from '../utils/cacheHelper'

import entranceVisual from '../images/assets/entrance_visual.png'
import banner from '../images/assets/banner.jpg'
import tick from '../images/assets/tick_icon.png'
import box from '../images/assets/box.png'
import dummy from '../images/assets/bayer_logo.png'

export const fixedAssets = {
  banner,
  entranceVisual,
  tick,
  box,
  dummy,

}

export const PreCache = ({ children }) => {
  const [pageLoading, setPageLoading] = useState(false)

  const preloadAssets = useCallback(async () => {
    setPageLoading(true)
    await cacheWebImages(Object.values(fixedAssets))
    setPageLoading(false)
  }, [])

  useEffect(() => {
    preloadAssets()
  }, [preloadAssets])

  return (
    <Loader loading={pageLoading} fullPage>
      <>{children}</>
    </Loader>
  )
}

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CurrentUserType } from 'src/ducks/auth'
import { RoutesType } from 'src/routes'
import Amplitude from 'src/utils/amplitudeHelper'
import { showGlobalModal } from '../../ducks/layout'
import { GlobalModalTypes } from '../GlobalModals/GlobalModalContainer'
import { EntrySelectionModal, MultiDestinationsType } from './EntrySelectionModal/EntrySelectionModal'
import { PinWrapper } from './styled'

export enum LobbyEntryTypes {
  'DirectRoute', // direct route string
  'MultiRoutes', // array of route strings
  'Helpdesk', // trigger showHelpDesk
  'GlobalModal', // trigger globalModal
}

interface CommonProps {
  css: string
  pinText?: string
  pinImage?: string
  entryOpenImage?: string
  entryCloseImage?: string
}

type EntryTypeProps =
  | { type: LobbyEntryTypes.DirectRoute; destination: RoutesType }
  | { type: LobbyEntryTypes.MultiRoutes; destinations: Array<MultiDestinationsType>; selectionTitle: string }
  | { type: LobbyEntryTypes.GlobalModal; modalId: GlobalModalTypes }
  | { type: LobbyEntryTypes.Helpdesk }

export type LobbyEntryProps = CommonProps & EntryTypeProps

export const LobbyEntry = (props: LobbyEntryProps & { currentUser: CurrentUserType }) => {
  const { currentUser, css, pinText, pinImage, entryOpenImage, entryCloseImage } = props

  const [multiRoutesModal, setMultiRoutesModal] = useState<boolean>(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const showHelpDesk = () => {
    // const cpt = window.chaport
    // cpt.on('ready', function () {
    //   cpt.setVisitorData({
    //     name: currentUser.fullName,
    //     email: currentUser.email,
    //   })
    //   cpt.open()
    // })
    // cpt.on('collapsed', function () {
    //   cpt.close()
    // })
    // Amplitude.clickHelpDesk()
  }

  const handleEntryClick = () => {
    if (props.type === LobbyEntryTypes.DirectRoute) {
      history.push(props.destination)
    }
    if (props.type === LobbyEntryTypes.MultiRoutes) {
      setMultiRoutesModal(true)
    }
    if (props.type === LobbyEntryTypes.GlobalModal) {
      dispatch(showGlobalModal(props.modalId))
    }
    if (props.type === LobbyEntryTypes.Helpdesk) {
      showHelpDesk()
    }
  }

  return (
    <>
      {props.type === LobbyEntryTypes.MultiRoutes && props.destinations && multiRoutesModal && (
        <EntrySelectionModal
          destinations={props.destinations}
          modalTitle={props.selectionTitle}
          handleClose={() => {
            setMultiRoutesModal(false)
          }}
        />
      )}
      <PinWrapper css={css} onClick={handleEntryClick}>
        {pinText && <h5 className="pinText">{pinText}</h5>}
        {pinImage && <img alt="pins" className="pinImage" src={pinImage} />}
        {entryOpenImage && entryCloseImage && (
          <>
            <img
              className="entryImage"
              alt="entryImage"
              src={entryCloseImage}
              onMouseOver={(e) => (e.currentTarget.src = entryOpenImage)}
              onMouseOut={(e) => (e.currentTarget.src = entryCloseImage)}
            />
          </>
        )}
      </PinWrapper>
    </>
  )
}

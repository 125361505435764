const TOGGLE_MUTE = 'TOGGLE_MUTE'
const TOGGLE_QUESTION_FORM = 'TOGGLE_QUESTION_FORM'
const MUTE_MUSIC = 'MUTE_MUSIC'
const UNMUTE_MUSIC = 'UNMUTE_MUSIC'
const SHOW_GLOBAL_MODAL = 'SHOW_GLOBAL_MODAL'
const HIDE_GLOBAL_MODAL = 'HIDE_GLOBAL_MODAL'

const defaultMuted = () => {
  if (localStorage.getItem('muted') === null) {
    localStorage.setItem('muted', false)
    return false
  }
  return localStorage.getItem('muted') === 'true'
}

const initialState = {
  showForm: false,
  muted: defaultMuted(),
  globalModal: null,
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MUTE:
      localStorage.setItem('muted', !state.muted)
      return {
        ...state,
        muted: !state.muted,
      }

    case MUTE_MUSIC:
      return {
        ...state,
        muted: true,
      }

    case UNMUTE_MUSIC:
      return {
        ...state,
        muted: false,
      }

    case TOGGLE_QUESTION_FORM:
      return {
        ...state,
        showForm: !state.showForm,
      }

    case SHOW_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: action.globalModal,
      }

    case HIDE_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: null,
      }

    default:
      return state
  }
}

export function toggleMute() {
  return { type: TOGGLE_MUTE }
}

export function muteMusic() {
  return { type: MUTE_MUSIC }
}

export function unmuteMusic() {
  return { type: UNMUTE_MUSIC }
}

export function toggleShowForm() {
  return { type: TOGGLE_QUESTION_FORM }
}

export function showGlobalModal(globalModal) {
  return { type: SHOW_GLOBAL_MODAL, globalModal: globalModal }
}

export function hideGlobalModal() {
  return { type: HIDE_GLOBAL_MODAL }
}

import styled from 'styled-components'

interface PinWrapperProps {
  css: string
}

export const PinWrapper = styled.div<PinWrapperProps>`
  ${(props) => props.css}
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    .pins {
      animation-name: bounce;
      animation-duration: 1s;
      animation-fill-mode: both;
    }
  }

  .pinImage,
  .pinText {
    position: absolute;
  }

  .pinImage {
    margin-top: -50px;
    z-index: 10;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: heartbeat 7s infinite;
  }

  .entryImage {
    margin-top: 0;
    animation: none;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: 100%;
    &:hover {
      animation: none;
    }
  }
`

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideGlobalModal } from 'src/ducks/game'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import WelcomeModal from './WelcomeModal'

export default function ModalContainer() {
  const { globalModal, currentUser, booth } = useSelector((state) => state.game)
  const dispatch = useDispatch()

  const hideModal = () => {
    dispatch(hideGlobalModal())
  }

  switch (globalModal) {
    case "welcome":
      return <WelcomeModal showModal={true} hideModal={hideModal} />
    case "error":
      return <ErrorModal boothName={booth?.title || ""} showModal={true} hideModal={hideModal} currentUser={currentUser} />
    case "success":
      return <SuccessModal boothName={booth?.title || ""} showModal={true} hideModal={hideModal} currentUser={currentUser} />
    default:
      return <></>
  }
}

import axios from 'axios'
import { toast } from 'react-toastify'
import config from '../config'

const LOGIN_STATUS = 'LOGIN_STATUS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'
const GET_BOOTHS = 'GET_BOOTHS'
const GET_BOOTH = 'GET_BOOTH'
const SET_COMPLETED = 'SET_COMPLETED'
const SHOW_GLOBAL_MODAL = 'SHOW_GLOBAL_MODAL'
const HIDE_GLOBAL_MODAL = 'HIDE_GLOBAL_MODAL'

const initialState = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  currentUser: {},
  user: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  completed: false,
  globalModal: null,
  booth: null,
  booths: [],
}

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_STATUS:
      return {
        ...state,
        currentUser: action.user,
        isAuthenticated: true,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
      }
    case GET_BOOTHS:
      return {
        ...state,
        booths: action.booths,
      }
    case GET_BOOTH:
      return {
        ...state,
        booth: action.booth,
      }
    case SET_COMPLETED:
      return {
        ...state,
        completed: action.completed,
      }
    case SHOW_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: action.globalModal,
      }

    case HIDE_GLOBAL_MODAL:
      return {
        ...state,
        globalModal: null,
      }
    default:
      return state
  }
}
export function refreshUser() {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/auto_login`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(
        ({ status, data }) => {
          if (status === 200 && data.success) {
            dispatch(loginRequest(data.user))
          } else {
            dispatch(rejectLogin())
          }
        },
        (error) => {
          dispatch(rejectLogin())
          console.error('Error: ', error)
        }
      )
  }
}
export function loginUser(data) {
  return (dispatch) => {
    return axios
      .post(`${config.apiUrl}/api/login`, data, {})
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          localStorage.setItem('token', data.token)
          dispatch(loginRequest(data.user))
          // dispatch(showGlobalModal("welcome"))
        }
      })
      .catch((error) => {
        // console.error('Error: ', err)
        console.log(error.response)
        dispatch(rejectLogin(error.response?.data?.message))
      })
  }
}
export function getBooths(search = '') {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/booths`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
          search: search,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveBooths(data.booth))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}
export function getBooth(boothId) {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/booths/${boothId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveBooth(data.booth))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function sendResult(history, data) {
  return (dispatch) => {
    return axios
      .post(`${config.apiUrl}/api/stamps/validate-qr`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(({ status, data }) => {
        dispatch(loginRequest(data.user))
        dispatch(showGlobalModal('success'))
        history.push('/home')
      })
      .catch((error) => {
        console.error('Error: ', error)
        dispatch(showGlobalModal('error'))
        history.push('/home')
      })
  }
}
export function enterDraw() {
  return (dispatch) => {
    return axios
      .post(
        `${config.apiUrl}/api/participate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(loginRequest(data.user))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}
function loginRequest(user) {
  return {
    type: LOGIN_STATUS,
    user: user,
  }
}
function receiveBooths(booths) {
  return {
    type: GET_BOOTHS,
    booths,
  }
}
function receiveBooth(booth) {
  return {
    type: GET_BOOTH,
    booth,
  }
}
function updateStatus(completed) {
  return {
    type: SET_COMPLETED,
    completed: completed,
  }
}

function rejectLogin(message = '') {
  if (message) toast.error(message)
  return {
    type: LOGIN_FAILURE,
  }
}

export function showGlobalModal(globalModal) {
  return { type: SHOW_GLOBAL_MODAL, globalModal: globalModal }
}

export function hideGlobalModal() {
  return { type: HIDE_GLOBAL_MODAL }
}

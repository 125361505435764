import React from 'react'
import { Modal } from 'react-bootstrap'
import { fixedAssets } from 'src/containers/PreCache'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    border: none;
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
  }
  .modal-header {
    border: none;
    padding: 0.75rem 1rem;
    align-items: center;
    .close {
      color: #60605f;
      opacity: 1;
      padding: 16px;
      font-size: 40px;
      font-weight: 400;
      text-shadow: none;
    }
    .modal-title {
      width: 100%;
    }
  }
  .title {
    text-align: center;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 28px;
      height: 28px;
    }
    span {
      font-weight: 700;
      font-size: 18px;
      margin-top: 4px;
      margin-left: 8px;
    }
  }
  .modal-body {
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 24px;

    ol {
      margin: 1.5rem 0;
      padding-left: 16px;
      padding-right: 8px;
      li {
        margin-bottom: 12px;
      }
    }
  }
  .start-btn {
    color: #fff;
    background: #4a1412;
    cursor: pointer;
    padding: 10px;
    border-radius: 23px;
    width: 90%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 13.5px;
      height: 12.7px;
    }
    span {
      font-weight: 700;
      font-size: 18px;
      margin-right: 8px;
    }
  }
`

export default function WelcomeModal({ showModal, hideModal }) {
  return (
    <StyledModal backdrop={true} show={showModal} onHide={hideModal} aria-labelledby="welcome-modal" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="title">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.5 10.6544C0.5 5.1315 4.97715 0.65435 10.5 0.65435C16.0228 0.65435 20.5 5.1315 20.5 10.6544C20.5 16.1772 16.0228 20.6544 10.5 20.6544C4.97715 20.6544 0.5 16.1772 0.5 10.6544ZM12.1694 6.6372C11.2578 5.83955 9.74265 5.83955 8.83106 6.6372C8.51134 6.91695 8.02537 6.88456 7.74561 6.56484C7.46586 6.24511 7.49826 5.75914 7.81798 5.47939C9.30961 4.17421 11.6909 4.17421 13.1825 5.47939C14.7344 6.83727 14.7344 9.08681 13.1825 10.4447C12.9193 10.675 12.6293 10.8637 12.3236 11.0116C11.63 11.3473 11.2695 11.8053 11.2695 12.1928V12.962C11.2695 13.3869 10.9251 13.7313 10.5002 13.7313C10.0754 13.7313 9.731 13.3869 9.731 12.962V12.1928C9.731 10.881 10.8174 10.0314 11.6534 9.62683C11.8408 9.53617 12.0144 9.42255 12.1694 9.28689C13.0208 8.54194 13.0208 7.38214 12.1694 6.6372ZM10.5 16.8082C10.9248 16.8082 11.2692 16.4638 11.2692 16.039C11.2692 15.6141 10.9248 15.2697 10.5 15.2697C10.0752 15.2697 9.73077 15.6141 9.73077 16.039C9.73077 16.4638 10.0752 16.8082 10.5 16.8082Z"
                fill="#E97D8A"
              />
            </svg>
            <span className="how-to-play">How-To-Play Guide</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ol>
          <li>Visit a sponsor booth and click the camera icon to scan the provided QR code at the booth.</li>
          <li>A collected stamp will be shown with a check icon.</li>
          <li>Collect all stamps to qualify for the Lucky Draw.</li>
          <li>After collecting all stamps, tap the ‘Submit Entry’ button to enter the Lucky Draw.</li>
          <li>
            The deadline to join the Lucky Draw is{' '}
            <strong>
              12PM on 17<sup>th</sup> August 2024
            </strong>
            .
          </li>
        </ol>
        <div className="start-btn" onClick={hideModal}>
          <span>Start collecting stamps</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.15436 1.65436L14.5 8.00001L8.15436 14.3457" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.4999 8L1 8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </Modal.Body>
    </StyledModal>
  )
}

import React, { useState } from 'react'
import { ScreenStyle } from './styled'
import { fixedAssets } from 'src/containers/PreCache'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from 'src/ducks/game'
import { Redirect } from 'react-router'
import routes from 'src/routes'

export const EntranceScreen = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { isAuthenticated } = useSelector((state) => state.game)

  const handleRegister = () => {
    if (!email.length || !password.length) {
      toast('Please fill in all fields before start.')
      return
    }
    dispatch(
      loginUser({
        email,
        password,
      })
    )
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }
  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  }
  return (
    <ScreenStyle>
      <div className="">
        <img src={fixedAssets.banner} className="masterhead" alt="Banner Visual" />
        <img src={fixedAssets.entranceVisual} className="visual" alt="Home Visual" />
      </div>

      <h5 className="pinText mx-auto w-75">Collect all stamps to stand a chance to win the lucky draw contest!</h5>

      <form onSubmit={handleRegister}>
        <input type="email" className="login-input" placeholder="Email" value={email} onChange={onEmailInput} />
        <input type="password" className="login-input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </form>

      <h5 className="resetText mx-auto w-75 font-italic">Log-in via the details of your registered account of the conference.</h5>
      <h5 className="resetText mx-auto w-75">
        Forgot password?{' '}
        <a href="https://asad2024.com/password/new" target="_blank">
          Reset Password
        </a>
      </h5>

      <footer className="mt-4 mb-5">
        <button className="cta-btn" onClick={handleRegister}>
          <svg width="36" height="36" viewBox="0 0 36 36">
            <circle cx="18" cy="18" r="18" />
            <g transform="translate(11 13)">
              <path
                d="M8.822.219l4.95,4.753a.726.726,0,0,1,0,1.056l-4.95,4.753a.8.8,0,0,1-1.1,0,.726.726,0,0,1,0-1.056l3.622-3.478H.778a.747.747,0,1,1,0-1.494H11.345L7.723,1.275a.726.726,0,0,1,0-1.056A.8.8,0,0,1,8.822.219Z"
                fill="#fff"
              />
            </g>
          </svg>
          <span>Start Collecting Stamps</span>
          <span></span>
        </button>
      </footer>
    </ScreenStyle>
  )
}
